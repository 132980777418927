import React, { useState } from 'react';
import { connect } from 'react-redux'
import { Link, navigate } from 'gatsby';
import { TextField, Button, CircularProgress, Switch, FormControlLabel } from '@material-ui/core';
import validator from 'validator';

import SEO from '../components/seo';

import * as AccountActions from '../actions/account-actions';
import * as Errors from '../constants/errors';

import styles from '../styles/login.module.scss';

const LoginPage = ({ location, auth, login }) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [inputErrors, setInputErrors] = useState({});
    const [values, setValues] = useState(
        {
            email: '',
            password: '',
            remember_me: true
        }
    );

    const handleChange = (e) => {
        if (e.target) {
            const { name, value } = e.target;

            setValues(state => ({
                ...state,
                [name]: value
            }));

            if (inputErrors[name]) {
                setInputErrors(state => ({
                    ...state,
                    [name]: null
                }));
            }
        }
    };

    const checkChange = (e) => {
        const { name, checked } = e.target;

        setValues(state => ({
            ...state,
            [name]: checked
        }));
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        const { email, password, remember_me } = values;

        const errs = {};

        if (validator.isEmpty(email)) {
            errs['email'] = 'Email Required';
        } else if (!validator.isEmail(email)) {
            errs['email'] = 'Invalid Email';
        }

        if (validator.isEmpty(password)) {
            errs['password'] = 'Password Required';
        }

        if (Object.keys(errs).length) {
            setInputErrors(errs);
            return false;
        }

        setLoading(true);
        setErrors(null);

        const result = await login(
            {
                email,
                password,
                persistent: remember_me
            }
        );

        setLoading(false);

        if (result.ok) {
            let pathname = '/my-account/';
            let state;

            if (location && location.state && location.state.redirect) {
                const { redirect } = location.state;

                if (typeof (redirect) === 'object') {
                    pathname = redirect.pathname;
                    state = redirect.state;
                } else {
                    pathname = redirect;
                }
            }

            navigate(
                pathname,
                {
                    state,
                    replace: true
                }
            );
            return true;
        }

        if (!result.error) {
            setErrors([
                'Sorry, an error occurred.',
                'Please try again.'
            ]);
            return false;
        }

        const { type } = result.error;

        switch (type) {
            case Errors.LOGIN_FAILED:
                setErrors([
                    'Invalid email or password.',
                    'Please try again.'
                ]);
                return false;

            default:
                setErrors([
                    'Sorry, an error occurred.',
                    'Please try again.'
                ]);
                return false;
        }
    };

    return (
        <>
            <SEO
                title='Login'
                path='/login/'
                description='Login to your Roselium account.'
            />
            <section className={styles.main}>
                <div className={styles.content}>
                    <form
                        noValidate
                        onSubmit={onSubmit}
                    >
                        <h1>Login</h1>
                        {errors &&
                            <ul className={styles.errors}>
                                {errors.map((x, i) => (
                                    <li key={i}>{x}</li>
                                ))}
                            </ul>
                        }
                        <TextField
                            id='email'
                            name='email'
                            label={inputErrors['email'] || 'Email'}
                            error={Boolean(inputErrors['email'])}
                            type='email'
                            autoComplete='email'
                            value={values.email}
                            onChange={handleChange}
                            margin='normal'
                            fullWidth={true}
                            className={styles.email}
                        />
                        <TextField
                            id='password'
                            name='password'
                            label={inputErrors['password'] || 'Password'}
                            error={Boolean(inputErrors['password'])}
                            type='password'
                            autoComplete='current-password'
                            value={values.password}
                            onChange={handleChange}
                            margin='normal'
                            fullWidth={true}
                            className={styles.textField}
                        />
                        <Button
                            type='submit'
                            variant='contained'
                            color='primary'
                            fullWidth={true}
                            className={styles.signin}
                            disabled={loading}
                        >
                            {loading ?
                                <CircularProgress
                                    color='inherit'
                                    size={24}
                                    className={styles.loading}
                                /> :
                                'SIGN IN'
                            }
                        </Button>
                        <FormControlLabel
                            control={
                                <Switch
                                    color='primary'
                                    name='remember_me'
                                    checked={values['remember_me']}
                                    onChange={checkChange}
                                />
                            }
                            label='Remember Me'
                            classes={{
                                root: styles.rememberMe,
                                label: styles.label
                            }}
                        />
                        <Button
                            type='button'
                            variant='text'
                            component={Link}
                            to='/forgot-password/'
                            size='small'
                            color='secondary'
                            className={styles.forgot}
                        >
                            Forgot your password?
                        </Button>
                    </form>
                </div>
            </section>
        </>
    )
};

const mapState = (state) => ({ auth: state.auth });
const mapDispatch = { login: AccountActions.login };

export default connect(mapState, mapDispatch)(LoginPage);
